import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import RChat from '../views/RChat.vue';
import Error from '../views/Error.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'attrrchat',
    props: true,
    component: RChat
  },
  {
    path: '/:chatToken',
    name: 'rchat',
    props: true,
    component: RChat
  },
  {
    path: '/invalid',
    name: 'error',
    props: true,
    component: Error
  },
  {
    path: '*',
    redirect: 'error'
  }
];
/* mode: "hash" or "abstract" */
const router = new VueRouter({
  routes: routes,
  mode: process.env.VUE_APP_ROUTER_MODE
});

export default router;
