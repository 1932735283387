/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Module,
  VuexModule,
  getModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import store from '@/store';
import {
  chatMessageUploadMedia,
  doGetChatMessages,
  doSendChatMessage,
  doSendTemplateChatMessage,
  doConfirmReadChatMessage,
  clearBearerToken,
  getChatToken,
  setBearerToken
} from '@/store/api';
import {
  ChatMessageRequest,
  InitTokenResponse,
  SendChatMessageRequest
} from '@/store/models';
@Module({
  dynamic: true,
  namespaced: true,
  name: 'recruiterChatModule',
  store
})
class RecruiterChatModule extends VuexModule {
  public token: InitTokenResponse | null = null;

  @Mutation
  public setToken(token: InitTokenResponse | null) {
    this.token = token;
  }

  @Action({ rawError: true })
  public async retrieveToken(chatToken: string) {
    const token = await getChatToken(chatToken);
    if (token) {
      this.setToken(token);
      setBearerToken(token.apiToken);
    } else {
      this.setToken(null);
      clearBearerToken();
    }
  }

  @Action({ rawError: true })
  public async getChatMessages(requestObj: ChatMessageRequest) {
    try {
      const chatMessages = await doGetChatMessages(requestObj);
      return chatMessages;
    } catch (error) {
      throw new Error(error);
    }
  }

  @Action({ rawError: true })
  public async sendChatMessage(requestObj: SendChatMessageRequest) {
    try {
      const newSlug = await doSendChatMessage(requestObj);
      return newSlug;
    } catch (error) {
      throw new Error(error);
    }
  }

  @Action({ rawError: true })
  public async sendTemplateChatMessage(requestObj: SendChatMessageRequest) {
    try {
      const chatMessage = await doSendTemplateChatMessage(requestObj);
      return chatMessage;
    } catch (error) {
      throw new Error(error);
    }
  }

  @Action({ rawError: true })
  public async sendMediaChatMessage(requestObj: any) {
    try {
      const chatMessage = await chatMessageUploadMedia(requestObj);
      return chatMessage;
    } catch (error) {
      throw new Error(error);
    }
  }

  @Action({ rawError: true })
  public async confirmReadChatMessage(requestObj: ChatMessageRequest) {
    try {
      await doConfirmReadChatMessage(requestObj);
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default getModule(RecruiterChatModule);
