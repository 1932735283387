import Vue from 'vue';

import './styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Quasar from 'quasar/src/vue-plugin.js';import QLayout from 'quasar/src/components/layout/QLayout.js';import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import QPage from 'quasar/src/components/page/QPage.js';import QHeader from 'quasar/src/components/header/QHeader.js';import QFooter from 'quasar/src/components/footer/QFooter.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QCardActions from 'quasar/src/components/card/QCardActions.js';import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';import QUploader from 'quasar/src/components/uploader/QUploader.js';import Notify from 'quasar/src/plugins/Notify.js';;

Vue.use(Quasar, {
  config: {},
  components: {
    QLayout,
    QPageContainer,
    QPage,
    QHeader,
    QFooter,
    QBtn,
    QCard,
    QCardSection,
    QCardActions,
    QInnerLoading,
    QSpinnerGears,
    QChatMessage,
    QUploader
  },
  plugins: {
    Notify
  }
});
