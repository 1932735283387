/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  de: {
    message: {
      chatDialogHeader: 'Chat mit ',
      triggerChatMessageText_FORMAL:
        'Hallo {0}, wir würden gerne wegen Ihrer Bewerbung mit Ihnen in Kontakt treten. Das müssen Sie uns genehmigen, in dem Sie auf diese Nachricht beliebig antworten.',
      triggerChatMessageText_CASUAL:
        'Hallo {0}, wir würden Dich gerne zu Deiner Bewerbung kontaktieren. Das musst Du uns genehmigen, in dem Du auf diese Nachricht beliebig antwortest.',
      failedProcessMsg:
        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut',
      failedTokenErrMsg:
        'Token ist abgelaufen, bitte starten Sie den Chat über Ihr Rekrutierungssystem neu',
      legalNoticeFLbl: 'Impressum',
      privacyPolicyFLbl: 'Datenschutz',
      errorPageMsg:
        'Sie haben möglicherweise einen ungültigen Link verwendet, bitte schließen Sie den Chat und starten Sie ihn erneut.',
      newNotificationsMsg: 'new message',
      applicationTitle: 'WhatsApp-Bewerbung mit PitchYou ®'
    }
  },
  en: {
    message: {
      chatDialogHeader: 'Chat with ',
      triggerChatMessageText_FORMAL:
        'Hello {0}, we would like to get in touch with you regarding your application. You must approve this by replying any text to this message.',
      triggerChatMessageText_CASUAL:
        'Hello {0}, we would contact you regarding your application. You have to approve this by replying any text to this message.',
      failedProcessMsg: 'Something went wrong please try again later',
      failedTokenErrMsg:
        'Token has expired, please restart chat from your recruiting system',
      legalNoticeFLbl: 'Legal notice',
      privacyPolicyFLbl: 'Privacy policy',
      errorPageMsg:
        'You may have used an invalid link, please close and restart chat process',
      newNotificationsMsg: 'new message',
      applicationTitle: 'WhatsApp-Bewerbung mit PitchYou ®'
    }
  }
};

const i18n = new VueI18n({ locale: 'de', fallbackLocale: 'en', messages });
export default i18n;
