



/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';

@Component
export default class ServerNotification extends Vue {
  @Prop(String) public readonly channelId!: string;
  private socket: any;
  private stompClient: any;
  private subscription: any;

  public mounted() {
    this.connect();
  }

  private connect() {
    try {
      this.socket = new SockJS(process.env.VUE_APP_SOCKET_URI);
      this.stompClient = Stomp.over(this.socket, {
        debug: false,
        protocols: ['v12.stomp']
      });
      this.stompClient.reconnectDelay = 10000;
      this.stompClient.connect(
        {},
        (frame: any) => {
          this.subscription = this.stompClient.subscribe(
            '/notifychannel/' + this.channelId,
            (tick: any) => {
              this.onMessageReceived(tick);
            }
          );
        },
        (error: any) => {
          console.log('error while connecting to socket', error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  private cleanUp() {
    try {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.disconnect();
        this.socket = null;
      }
    } catch (err) {
      console.log(err);
    }
  }

  private disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  }

  @Emit('on-py-notification')
  private onMessageReceived(payload: any): any {
    if (payload && payload.body) {
      return payload.body;
    }
    return {};
  }
}
