








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoadingIndicator extends Vue {
  @Prop(Boolean) private readonly showLoading: boolean | undefined;
}
