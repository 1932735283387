/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './quasar';
import i18n from '@/plugins/i18n';
import VueCustomElement from 'vue-custom-element';
import RChat from './views/RChat.vue';

Vue.config.productionTip = false;
Vue.use(VueCustomElement);
if (process.env.VUE_APP_MODE === 'widget') {
  Vue.prototype._i18n = i18n;
  Vue.customElement('pitchyou-rchat', (RChat as any).options);
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#pitchyou-rchat');
