



























































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Mixins, Emit } from 'vue-property-decorator';
import {
  ChatMessage,
  ChatMessageRequest,
  Interview,
  NotificationMessage,
  SendChatMessageRequest
} from '@/store/models';
import Common from '@/mixins/common';
import recruiterChatModule from '@/store/modules/recruiterChatModule';
import ServerNotification from '@/components/ServerNotification.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { find, findIndex } from 'lodash-es';
import notificationSound from '@/assets/definite-555.mp3';

@Component({
  components: {
    ServerNotification,
    LoadingIndicator
  }
})
export default class RecruiterChat extends Mixins(Common) {
  private channelId: string | null = null;
  private messages: ChatMessage[] = [];
  private processing = false;
  private messageSendAllowed = false;
  private newChatMessageText: string | null = null;
  private interview: Partial<Interview> = {};
  private recruiterName: Partial<string> = '';
  private chatTriggerAllowed = false;

  public mounted() {
    this.initReset();
    if (recruiterChatModule.token) {
      this.interview = recruiterChatModule.token.interview;
      this.recruiterName = recruiterChatModule.token.recruiterName;
      this.channelId = recruiterChatModule.token.tenant;
    } else {
      this.showErrorMsgToast(
        this,
        this.$t('message.failedProcessMsg') as string
      );
    }
  }

  private initReset() {
    this.channelId = null;
    this.interview = {};
    this.recruiterName = '';
    this.messages = [];
    this.processing = false;
    this.messageSendAllowed = false;
    this.newChatMessageText = '';
  }

  private async onFileAdded(files: Array<File>) {
    if (files && files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.processing = true;
      try {
        const mediaSendMessage: ChatMessage = await recruiterChatModule.sendMediaChatMessage(
          formData
        );
        this.messages.push(mediaSendMessage);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.resetFilePicker();
        this.processing = false;
      }
    }
  }

  private async onSendMessage() {
    const inputText: string = this.newChatMessageText as string;
    try {
      this.newChatMessageText = null;
      const chatMessageInput: any = this.$refs.chatMessageInput;
      chatMessageInput.focus();
      if (!inputText || inputText.length < 1) {
        return;
      }
      document.title = this.$t('message.applicationTitle') as string;
      const requestObj: SendChatMessageRequest = {
        text: inputText as string,
        interviewGuid: this.interview.guid || ''
      };
      const newMessage: ChatMessage = {
        sentAt: new Date().getTime(),
        userName: this.recruiterName || '',
        slug: 'newSlug',
        text: inputText as string,
        mediaType: 'TEXT',
        sent: true,
        processed: false,
        received: false,
        read: false
      };
      this.messages.push(newMessage);
      const newSlug = await recruiterChatModule.sendChatMessage(requestObj);
      const message: any = find(this.messages, function(t) {
        return t.slug === 'newSlug';
      });
      message.slug = newSlug;
    } catch (error) {
      console.log(error);
      if (error && error.message && error.message.includes('TOKEN_ERROR')) {
        this.$router.replace({ name: 'error' });
      } else if (
        error &&
        error.message &&
        error.message.includes('CHAT_TIMEOUT')
      ) {
        this.messages.splice(this.messages.length - 1, 1);
        this.newChatMessageText = inputText;
        this.messageSendAllowed = false;
        await this.onSendTriggerChatMessage();
      } else {
        this.showErrorMsgToast(
          this,
          this.$t('message.failedProcessMsg') as string
        );
      }
    }
  }

  private async onSendTriggerChatMessage() {
    try {
      const requestObj: SendChatMessageRequest = {
        text: this.$t(
          'message.triggerChatMessageText_' + this.interview.salutationType
        ) as string,
        interviewGuid: this.interview.guid || ''
      };
      this.messages.push(
        await recruiterChatModule.sendTemplateChatMessage(requestObj)
      );
      this.chatTriggerAllowed = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  private async loadMoreChatMessages(index: any, done: any) {
    done(await this.loadChatMessages());
  }

  private async loadChatMessages() {
    try {
      const requestObj: ChatMessageRequest = {
        interviewGuid: this.interview.guid || '',
        slug: this.getLastMessageSlug()
      };
      const response = await recruiterChatModule.getChatMessages(requestObj);
      if (response) {
        for (let index = 0; index < response.messages.length; index++) {
          const element = response.messages[index];
          this.messages.splice(0, 0, element);
        }
        this.messageSendAllowed = response.messageSendAllowed;
        this.chatTriggerAllowed = response.chatTriggerAllowed;
        return response.messages.length < 15;
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  private getLastMessageSlug() {
    if (this.messages && this.messages.length) {
      return this.messages[0].slug;
    }
    return '';
  }

  private scrollChatToBottom() {
    try {
      const element: any = this.$refs.finalScrollPosition;
      element.scrollIntoView({ behavior: 'auto', block: 'start' });
    } catch (err) {
      console.log(err);
    }
  }

  private handleError(error: any) {
    console.log(error);
    if (error && error.message && error.message.includes('TOKEN_ERROR')) {
      this.$router.replace({ name: 'error' });
    } else {
      this.showErrorMsgToast(
        this,
        this.$t('message.failedProcessMsg') as string
      );
    }
  }

  private resetFilePicker(): any {
    const filePicker: any = this.$refs.filePicker;
    filePicker.reset();
    return filePicker;
  }

  private onFileUpload(): void {
    const filePicker: any = this.resetFilePicker();
    if (this.processing) {
      return;
    }
    filePicker.pickFiles();
  }

  @Emit('on-ipy-chat-closed')
  private onChatClose(): any {
    return {};
  }

  private onNotification(message: any) {
    const notificationMessage: NotificationMessage = JSON.parse(message);
    if (notificationMessage && this.interview) {
      if (
        'NEW_MESSAGE' == notificationMessage.type &&
        this.interview.guid == notificationMessage.guid
      ) {
        const newMessage: ChatMessage = {
          sentAt: new Date().getTime(),
          userName:
            (this.interview &&
              this.interview.candidate &&
              this.interview.candidate.name) ||
            '',
          slug: notificationMessage.slug,
          text: notificationMessage.message as string,
          mediaUrl: notificationMessage.mediaUrl,
          mediaType: notificationMessage.mediaType,
          sent: false,
          processed: false,
          received: false,
          read: false
        };
        this.messages.push(newMessage);
        this.confirmRead(notificationMessage.slug);
        this.messageSendAllowed = true;
        this.chatTriggerAllowed = true;
      } else if (
        notificationMessage &&
        'UPDATE_SENT_STATUS' == notificationMessage.type &&
        this.interview.guid == notificationMessage.guid
      ) {
        const messageIndex: number = findIndex(this.messages, function(t) {
          return t.slug == notificationMessage.slug;
        });
        if (messageIndex > -1) {
          this.messages[messageIndex].processed = notificationMessage.processed;
          this.messages[messageIndex].received = notificationMessage.received;
          this.messages[messageIndex].read = notificationMessage.read;
        }
      }
      const audio = new Audio(this.getNotificationMediaUri());
      audio.loop = false;
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function() {
            // Automatic playback started!
          })
          .catch(function() {
            // Automatic playback failed.
          });
      }
      document.title = this.$t('message.newNotificationsMsg') as string;
    }
  }

  private async confirmRead(slug: string) {
    try {
      const requestObj: ChatMessageRequest = {
        slug: slug,
        interviewGuid: (this.interview && this.interview.guid) || ''
      };
      await recruiterChatModule.confirmReadChatMessage(requestObj);
    } catch (error) {
      this.handleError(error);
    }
  }

  private getMediaUri(uri: string): string {
    return process.env.VUE_APP_API_BASE_URI + uri.replace(/^\/+/g, '');
  }

  private getNotificationMediaUri(): string {
    if (process.env.VUE_APP_MODE === 'widget') {
      return process.env.VUE_APP_API_BASE_NOTIFICATION_MEDIA;
    }
    return notificationSound;
  }
}
