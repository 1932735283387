




























import { Vue, Component } from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';

@Component({
  components: { Footer }
})
export default class Error extends Vue {}
