











/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Common from '@/mixins/common';
import recruiterChatModule from '@/store/modules/recruiterChatModule';
import RecruiterChat from '@/components/RecruiterChat.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

@Component({
  components: {
    RecruiterChat,
    LoadingIndicator
  }
})
export default class RChat extends Mixins(Common) {
  @Prop(String) public readonly chatToken: string | undefined;
  private tokenRetreived = false;
  private processing = false;
  private messageEvent: any = null;

  public mounted() {
    this.initReset();
    this.getTokenInfo();
  }

  private initReset() {
    this.tokenRetreived = false;
    this.processing = false;
    this.messageEvent = null;
    window.addEventListener(
      'message',
      event => (this.messageEvent = event),
      false
    );
  }

  private getTokenInfo() {
    console.log(this.chatToken);
    if (this.chatToken && this.chatToken.length) {
      this.processing = true;
      recruiterChatModule
        .retrieveToken(this.chatToken)
        .then(() => {
          this.tokenRetreived = true;
          this.processing = false;
        })
        .catch(() => {
          this.$router.replace({ name: 'error' });
        });
    } else {
      this.$router.replace({ name: 'error' });
    }
  }

  private onPyChatClosed() {
    const chatClosedEvent = new CustomEvent('pychatclosed', {
      detail: { token: this.chatToken },
      bubbles: true,
      cancelable: true,
      composed: true
    });
    this.tokenRetreived = false;
    if (
      this.messageEvent &&
      this.messageEvent.source &&
      this.messageEvent.origin
    ) {
      console.log('Posting message, trying to close window');
      this.messageEvent.source.postMessage(
        { command: 'pychatclosed', detail: { token: this.chatToken } },
        this.messageEvent.origin
      );
    } else if (window.opener) {
      console.log('Dispatching window openner event, trying to close window');
      window.opener.dispatchEvent(chatClosedEvent);
    } else if (window.parent) {
      console.log('Dispatching window parent event');
      window.parent.dispatchEvent(chatClosedEvent);
    } else {
      console.log('Dispatching window event, trying to close window');
      window.dispatchEvent(chatClosedEvent);
    }
    //Don't show chat anymore
    this.tokenRetreived = false;
  }

  get chatLayoutHeight() {
    return {
      height: this.$q.screen.height + 'px'
    };
  }
}
