/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Component } from 'vue-property-decorator';
@Component
export default class Common extends Vue {
  public showErrorMsgToast(thisContext: any, msg: string): void {
    thisContext.$q.notify({
      color: 'pink-7',
      icon: 'warning',
      position: 'top',
      message: msg
    });
  }

  public showSuccessMsgToast(thisContext: any, msg: string): void {
    thisContext.$q.notify({
      color: 'green',
      icon: 'done',
      position: 'top',
      message: msg
    });
  }

  public getFormattedDate(date: Date) {
    try {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return '';
    } catch (error) {
      return '';
    }
  }
}
