import { render, staticRenderFns } from "./RecruiterChat.vue?vue&type=template&id=34a1cffa&"
import script from "./RecruiterChat.vue?vue&type=script&lang=ts&"
export * from "./RecruiterChat.vue?vue&type=script&lang=ts&"
import style0 from "./RecruiterChat.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';import Mutation from 'quasar/src/directives/Mutation.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QToolbarTitle,QIcon,QBtn,QSeparator,QFooter,QInput,QPageContainer,QPage,QInfiniteScroll,QSpinner,QChatMessage,QUploader});qInstall(component, 'directives', {Mutation});
