/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import {
  ChatMessage,
  ChatMessageRequest,
  ChatMessageResponse,
  InitTokenResponse,
  SendChatMessageRequest
} from '@/store/models';

export const pitchYouAPi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URI
});

export function setBearerToken(token: string) {
  pitchYouAPi.defaults.headers.common.Authorization = 'Bearer ' + token;
}

export function clearBearerToken() {
  delete pitchYouAPi.defaults.headers.common.Authorization;
}

export async function getChatToken(
  chatToken: string
): Promise<InitTokenResponse> {
  return new Promise((resolve, reject) => {
    pitchYouAPi
      .get('token/v1/initToken/' + chatToken)
      .then(response => {
        if (response.data) {
          resolve(response.data as InitTokenResponse);
        }
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          reject(new Error('TOKEN_ERROR'));
        } else {
          reject(new Error('FAILED_INIT_CHAT_TOKEN'));
        }
      });
  });
}

export async function doGetChatMessages(
  requestObj: ChatMessageRequest
): Promise<ChatMessageResponse> {
  return new Promise((resolve, reject) => {
    pitchYouAPi
      .get('api/chat/v1/messages', {
        params: {
          interviewGuid: requestObj.interviewGuid,
          slug: requestObj.slug
        }
      })
      .then(response => {
        resolve(response.data as ChatMessageResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CHAT_MESSAGES_FAILED'));
      });
  });
}

export async function doSendChatMessage(
  requestObj: SendChatMessageRequest
): Promise<string> {
  return new Promise((resolve, reject) => {
    pitchYouAPi
      .post('api/chat/v1/send', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 417) {
            reject(new Error('CHAT_TIMEOUT'));
          }
        }
        reject(new Error('SEND_CHAT_MESSAGE_FAILED'));
      });
  });
}

export async function doSendTemplateChatMessage(
  requestObj: SendChatMessageRequest
): Promise<ChatMessage> {
  return new Promise((resolve, reject) => {
    pitchYouAPi
      .post('api/chat/v1/sendtrigger', requestObj)
      .then(response => {
        resolve(response.data as ChatMessage);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          console.log(error.response.status);
          if (error.response.status && error.response.status === 403) {
            console.log('matched');
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SEND_TEMPLATE_CHAT_MESSAGE_FAILED'));
      });
  });
}

export async function chatMessageUploadMedia(
  formData: any
): Promise<ChatMessage> {
  return new Promise((resolve, reject) => {
    pitchYouAPi
      .post('api/chat/v1/uploadmedia', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data as ChatMessage);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPLOAD_CHAT_MEDIA_ERROR'));
      });
  });
}

export async function doConfirmReadChatMessage(
  requestObj: ChatMessageRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouAPi
      .get('api/chat/v1/confirmread', {
        params: {
          slug: requestObj.slug,
          interviewGuid: requestObj.interviewGuid
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CONFIRM_READ_CHAT_MESSAGE_FAILED'));
      });
  });
}
